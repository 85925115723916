.u-text {
  &--upper {
    text-transform: uppercase;
  }

  &--weight-normal {
    font-weight: normal;
  }

  &--superheader {
    font-size: 4.3636363637rem;
    line-height: 3.5656565657rem;

    @include bp(medium) {
      font-size: 8.1818181818rem;
      line-height: 6.3636363636rem;
    }
  }

  &--serif {
    font-family: $font-serif;
  }
}

.u-link {
  &--naked {
    text-decoration: none;
  }

  &--dud {
    cursor: pointer;
  }
}

.u-p {
  font-size: 1rem !important;
  line-height: 1.5 !important;
}

.u-title {
  font-size: 2rem !important;
  line-height: 1.5 !important;
}

.u-columns .wp-block-group__inner-container {
  columns: 2 200px;
  column-gap: 18px;
}
