/* ==========================================================================
   #HEADINGS
   ========================================================================== */

/**
 * Simple default styles for headings 1 through 6. Anything more opinionated
 * than simple font-size changes should likely be applied via classes (see:
 * http://csswizardry.com/2016/02/managing-typography-on-large-apps/).
 */

h1 {
  font-family: $font-serif;
  font-size: 2.5rem;
  line-height: 1.3333;
  letter-spacing: 5%;
  opacity: 0.9;
}

h2 {
  font-family: $font-serif;
  letter-spacing: 5%;
  opacity: 0.9;
}

h3 {
  font-size: 1.1666666667rem; /* 14px */
  line-height: 1.2857142857; /* 18px */
  letter-spacing: 5%;
  opacity: 0.9;
}
