.c-btn {
  padding: 6px 18px;
  display: inline-block;
  color: $color-white !important;
  background: $color-black;
  line-height: 1;
  margin-right: 18px;
  border: 0 !important;
}

.c-btn--trigger {
  padding: 9px;
  border: 0 !important;
  cursor: pointer;
  height: 48px;
  width: 48px;
  display: block;
  background: $color-black no-repeat center;
  background-image: url("data:image/svg+xml,%3Csvg width='28' height='30' viewBox='0 0 28 30' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M21.2319 13.9999L4 25L4 30L27.2319 15L21.2319 13.9999Z' fill='white'/%3E%3Cpath d='M21.2319 15.9918L4 5L4 1.74845e-07L27.2319 15L21.2319 15.9918Z' fill='white'/%3E%3Cpath d='M4 1.74845e-07L4 30H0L1.31134e-06 0L4 1.74845e-07Z' fill='white'/%3E%3C/svg%3E"); // play

  &--close {
    background-image: url("data:image/svg+xml,%3Csvg width='30' height='4' viewBox='0 0 30 4' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='30' height='4' fill='white'/%3E%3C/svg%3E"); // minus
  }
}

.c-theme--film-and-tv,
.c-theme--vfx {
  .c-btn {
    filter: invert(1);
  }
}
