.c-underline-link {
  position: relative;
  border: none;

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: $color-white;
    transform: scale(0);
    transform-origin: center;
    transition: opacity 300ms, transform 300ms;
    z-index: 999;
  }

  &:hover {
    border: none;
  }

  &:hover::before,
  &:focus::before {
    transform: scale(1);
  }
}
