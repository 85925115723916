/* ==========================================================================
   #WRAPPER
   ========================================================================== */

/**
 * Page-level constraining and wrapping elements.
 */

.o-wrapper {
  @include clearfix();
  margin-right: auto;
  margin-left: auto;
  max-width: calc(1024px + 2rem);
  padding-left: 1rem;
  padding-right: 1rem;
}
