.c-accordians {
  margin-bottom: 60px;
}

.c-accordian {
  &__button {
    cursor: pointer;
    width: 100%;
    border-bottom: 1px solid;
    display: flex;
    padding: 18px 0;
    justify-content: space-between;
    align-items: center;
    user-select: none;
  }

  &__panel {
    @extend .o-grid;
    margin: 30px 0;
    display: none;
    overflow: hidden;
  }

  &__toggle {
    @extend .c-btn--trigger;
    background-image: url("data:image/svg+xml,%3Csvg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 13H30V17H0V13Z' fill='white'/%3E%3Cpath d='M17 1.74845e-07L17 30H13L13 0L17 1.74845e-07Z' fill='white'/%3E%3C/svg%3E") !important; // plus
  }
}

.c-accordian .c-accordian__button.js-active .c-accordian__toggle:after {
  content: none;
}

.c-accordian .c-accordian__button.js-active .c-accordian__toggle {
  background-image: url("data:image/svg+xml,%3Csvg width='30' height='4' viewBox='0 0 30 4' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='30' height='4' fill='white'/%3E%3C/svg%3E") !important; // minus
}

.c-accordian .c-accordian__button.js-active + .c-accordian__panel {
  display: grid;
}
