.c-tease--news:first-child {
  @include bp(large) {
    grid-row: 1 / 3;
    grid-column: 1 / 3;

    a {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      border: 0 !important;
    }

    img {
      flex-grow: 1;
      object-fit: cover;
    }
  }
}

.c-tease--journal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;

  a {
    border: 0 !important;
  }

  img {
    margin: 0 auto;
    display: block;
  }
}

.c-tease--clip {
  span {
    margin: 0.5em 0;
    color: rgb(150, 150, 150);
    border: 1px solid rgba(150, 150, 150, 0.85);
    font-size: 0.75em;
    padding: 0 3px;
    display: inline-block;
  }

  a {
    border-bottom: none;
  }

  @include bp(small) {
    a {
      position: relative;
      display: block;
      padding: 0;
      background: black;
    }

    img {
      transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
      margin: 0 !important;
      aspect-ratio: 16/9;
      object-fit: cover;
    }

    div {
      margin: 0 !important;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 1em;
      text-align: bottom;
      flex-direction: column;
      justify-content: flex-end;
      display: flex;
    }

    h2 {
      color: white;
      transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    }

    span {
      color: rgba(255, 255, 255, 0.75);
      border: 1px solid rgba(255, 255, 255, 0.75);
    }
  }
}

.c-tease--4\/3 {
  > a {
    aspect-ratio: 4/3;
  }

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}
