/* ==========================================================================
   #MARGINS & PADDING
   ========================================================================== */

.u-margin {
  margin: 30px !important;

  &-top {
    margin-top: 30px !important;

    &--medium {
      margin-top: 18px !important;
    }

    &--small {
      margin-top: 6px !important;
    }
  }

  &-right {
    margin-right: 30px !important;

    &--medium {
      margin-right: 18px !important;
    }

    &--small {
      margin-right: 6px !important;
    }
  }

  &-bottom {
    margin-bottom: 30px !important;

    &--medium {
      margin-bottom: 18px !important;
    }

    &--small {
      margin-bottom: 6px !important;
    }
  }

  &-left {
    margin-right: 30px !important;

    &--medium {
      margin-right: 18px !important;
    }

    &--small {
      margin-right: 6px !important;
    }
  }

  &-none {
    margin: 0 !important;
  }
}

.u-padding {
  padding: 30px !important;

  &-top {
    padding-top: 30px !important;
  }

  &-right {
    padding-right: 30px !important;
  }

  &-bottom {
    padding-bottom: 30px !important;
  }

  &-left {
    padding-right: 30px !important;
  }

  &-none {
    padding: 0 !important;
  }
}
