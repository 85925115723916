.c-lightbox {
  display: none;

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;

  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: $color-black;

  &__item {
    padding: 1rem;
    max-width: calc(1280px + 2rem);
    position: relative;
    z-index: 999999;
  }
}

.js-active-lightbox {
  .c-wrapper {
    opacity: 1;
    animation: none;
    width: 100%;
    max-width: none;
    overflow: hidden;
    height: calc(100vh - 92px);

    @include bp(medium) {
      height: 100vh;
    }
  }

  .c-nav {
    display: none;
  }

  .c-lightbox {
    display: flex;
  }
}
