@charset "UTF-8";
/*
  Project: Unit
  Author: Via Studios
 */
/* Fonts
   ========================================================================== */
/* Breakpoints
   ========================================================================== */
/* Colors
   ========================================================================== */
/* ==========================================================================
   #BOX-SIZING
   ========================================================================== */
/**
 * More sensible default box-sizing:
 * css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice
 */
html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

/* ==========================================================================
   #FONT-FACE
   ========================================================================== */
/**
 * Add font-display: swap to font-face blocks here
 * https://www.zachleat.com/web/comprehensive-webfonts/#font-display
 */
/* ==========================================================================
   #NORMALIZE
   ========================================================================== */
/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
       ========================================================================== */
/**
     * 1. Correct the line height in all browsers.
     * 2. Prevent adjustments of font size after orientation changes in
     *    IE on Windows Phone and in iOS.
     */
html {
  line-height: 1.15;
  /* 1 */
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
}

/* Sections
       ========================================================================== */
/**
     * Remove the margin in all browsers (opinionated).
     */
body {
  margin: 0;
}

/**
     * Add the correct display in IE 9-.
     */
article,
aside,
footer,
header,
nav,
section {
  display: block;
}

/**
     * Correct the font size and margin on `h1` elements within `section` and
     * `article` contexts in Chrome, Firefox, and Safari.
     */
h1, .u-h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

/* Grouping content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
figcaption,
figure {
  display: block;
}

/**
     * Add the correct margin in IE 8.
     */
figure {
  margin: 1em 40px;
}

/**
     * 1. Add the correct box sizing in Firefox.
     * 2. Show the overflow in Edge and IE.
     */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */
}

/**
     * Add the correct display in IE.
     */
main {
  display: block;
}

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/* Links
       ========================================================================== */
/**
     * 1. Remove the gray background on active links in IE 10.
     * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
     */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */
}

/* Text-level semantics
       ========================================================================== */
/**
     * 1. Remove the bottom border in Chrome 57- and Firefox 39-.
     * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
     */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */
}

/**
     * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
     */
b,
strong {
  font-weight: inherit;
}

/**
     * Add the correct font weight in Chrome, Edge, and Safari.
     */
b,
strong {
  font-weight: bolder;
}

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/**
     * Add the correct font style in Android 4.3-.
     */
dfn {
  font-style: italic;
}

/**
     * Add the correct background and color in IE 9-.
     */
mark {
  background-color: #ff0;
  color: #000;
}

/**
     * Add the correct font size in all browsers.
     */
small {
  font-size: 80%;
}

/**
     * Prevent `sub` and `sup` elements from affecting the line height in
     * all browsers.
     */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

/* Embedded content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
audio,
video {
  display: inline-block;
}

/**
     * Add the correct display in iOS 4-7.
     */
audio:not([controls]) {
  display: none;
  height: 0;
}

/**
     * Remove the border on images inside links in IE 10-.
     */
img {
  border-style: none;
}

/**
     * Hide the overflow in IE.
     */
svg:not(:root) {
  overflow: hidden;
}

/* Forms
       ========================================================================== */
/**
     * 1. Change the font styles in all browsers (opinionated).
     * 2. Remove the margin in Firefox and Safari.
     */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */
}

/**
     * Show the overflow in IE.
     */
button {
  overflow: visible;
}

/**
     * Remove the inheritance of text transform in Edge, Firefox, and IE.
     * 1. Remove the inheritance of text transform in Firefox.
     */
button,
select {
  /* 1 */
  text-transform: none;
}

/**
     * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
     *    controls in Android 4.
     * 2. Correct the inability to style clickable types in iOS and Safari.
     */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  /**
       * Remove the inner border and padding in Firefox.
       */
  /**
       * Restore the focus styles unset by the previous rule.
       */
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
     * Show the overflow in Edge.
     */
input {
  overflow: visible;
}

/**
     * 1. Add the correct box sizing in IE 10-.
     * 2. Remove the padding in IE 10-.
     */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
}

/**
     * Correct the cursor style of increment and decrement buttons in Chrome.
     */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

/**
     * 1. Correct the odd appearance in Chrome and Safari.
     * 2. Correct the outline style in Safari.
     */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
       * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
       */
}

[type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
     * 1. Correct the inability to style clickable types in iOS and Safari.
     * 2. Change font properties to `inherit` in Safari.
     */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */
}

/**
     * Correct the padding in Firefox.
     */
fieldset {
  padding: 0.35em 0.75em 0.625em;
}

/**
     * 1. Correct the text wrapping in Edge and IE.
     * 2. Correct the color inheritance from `fieldset` elements in IE.
     * 3. Remove the padding so developers are not caught out when they zero out
     *    `fieldset` elements in all browsers.
     */
legend {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  color: inherit;
  /* 2 */
  white-space: normal;
  /* 1 */
}

/**
     * 1. Add the correct display in IE 9-.
     * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
     */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */
}

/**
     * Remove the default vertical scrollbar in IE.
     */
textarea {
  overflow: auto;
}

/* Interactive
       ========================================================================== */
/*
     * Add the correct display in Edge, IE, and Firefox.
     */
details {
  display: block;
}

/*
     * Add the correct display in all browsers.
     */
summary {
  display: list-item;
}

/*
     * Add the correct display in IE 9-.
     */
menu {
  display: block;
}

/* Scripting
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
canvas {
  display: inline-block;
}

/**
     * Add the correct display in IE.
     */
template {
  display: none;
}

/* Hidden
       ========================================================================== */
/**
     * Add the correct display in IE 10-.
     */
[hidden] {
  display: none;
}

/* ==========================================================================
   #RESET
   ========================================================================== */
/**
 * A very simple reset that sits on top of Normalize.css.
 */
body, h1, .u-h1, h2, .u-h2, h3, .u-h3, h4, .u-h4, h5, .u-h5, h6,
blockquote, p, pre,
dl, dd, ol, ul,
figure,
hr,
fieldset, legend {
  margin: 0;
  padding: 0;
}

/**
 * Remove trailing margins from nested lists.
 */
li > ol,
li > ul {
  margin-bottom: 0;
}

/**
 * Remove default table spacing.
 */
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/**
 * 1. Reset Chrome and Firefox behaviour which sets a `min-width: min-content;`
 *    on fieldsets.
 */
fieldset {
  min-width: 0;
  /* [1] */
  border: 0;
}

/* ==========================================================================
   #SHARED
   ========================================================================== */
/**
 * Shared declarations for certain elements.
 */
/**
 * Always declare margins in the same direction:
 * csswizardry.com/2012/06/single-direction-margin-declarations
 */
address, h1, .u-h1, h2, .u-h2, h3, .u-h3, h4, .u-h4, h5, .u-h5, h6,
blockquote, p, pre,
dl, ol, ul,
figure,
hr,
table,
fieldset {
  margin-bottom: 1.3rem;
}

/**
 * Consistent indentation for lists.
 */
dd, ol, ul {
  margin-left: 1rem;
}

/* ==========================================================================
   #BLOCKQUOTES
   ========================================================================== */
blockquote {
  quotes: '“' '”' '‘' '’';
  font-style: italic;
}

blockquote p:first-child:before {
  content: open-quote;
}

blockquote p:last-child:after {
  content: close-quote;
}

button,
input,
optgroup,
select,
textarea {
  font-family: "akzidenz-grotesk", sans-serif !important;
  background: transparent;
  color: white;
  max-width: 350px;
  display: block;
  padding: 1rem 0;
  width: 100%;
  border: 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

input[type='submit'] {
  background: url("/wp-content/themes/unit-chisel/dist/assets/images/cta.svg");
  background-repeat: no-repeat;
  background-position: right;
  background-size: 1.25rem;
  padding-right: 1.5rem;
  cursor: pointer;
  width: auto;
  border: 0;
}

/* ==========================================================================
   #HEADINGS
   ========================================================================== */
/**
 * Simple default styles for headings 1 through 6. Anything more opinionated
 * than simple font-size changes should likely be applied via classes (see:
 * http://csswizardry.com/2016/02/managing-typography-on-large-apps/).
 */
h1, .u-h1 {
  font-family: "ivypresto-display", serif;
  font-size: 2.5rem;
  line-height: 1.3333;
  letter-spacing: 5%;
  opacity: 0.9;
}

h2, .u-h2 {
  font-family: "ivypresto-display", serif;
  letter-spacing: 5%;
  opacity: 0.9;
}

h3, .u-h3 {
  font-size: 1.1666666667rem;
  /* 14px */
  line-height: 1.2857142857;
  /* 18px */
  letter-spacing: 5%;
  opacity: 0.9;
}

/* ==========================================================================
   #HR
   ========================================================================== */
hr {
  display: block;
}

/* ==========================================================================
   #PAGE
   ========================================================================== */
/**
 * Simple page-level setup.
 *
 * 1. Set the default `font-size` and `line-height` for the entire project.
 * 2. Force scrollbars to always be visible to prevent awkward ‘jumps’ when
 *    navigating between pages that do/do not have enough content to produce
 *    scrollbars naturally.
 * 3. Ensure the page always fills at least the entire height of the viewport.
 */
html {
  font-family: "akzidenz-grotesk", sans-serif;
  font-size: 0.875rem;
  /* [1] */
  /* 14px */
  line-height: 1.5;
  /* [1] */
  min-height: 100%;
  /* [3] */
  overflow-y: scroll;
  /* [2] */
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

/* ==========================================================================
   #IMAGES
   ========================================================================== */
/**
 * 1. Fluid images for responsive purposes.
 * 2. Offset `alt` text from surrounding copy.
 * 3. Setting `vertical-align` removes the whitespace that appears under `img`
 *    elements when they are dropped into a page as-is. Safer alternative to
 *    using `display: block;`.
 */
img {
  font-style: italic;
  /* [2] */
  max-width: 100%;
  /* [1] */
  vertical-align: middle;
  /* [3] */
  height: auto;
}

/**
 * 1. If a `width` and/or `height` attribute have been explicitly defined, let’s
 *    not make the image fluid.
 */
img[width],
img[height] {
  max-width: none;
}

/* Image with 'data' attribute is hidden */
img[data-lazy-src] {
  /* we set the opacity to 0 */
  opacity: 0;
}

/* stylelint-disable */
/* Image without 'data' attribute is (or becomes) visible */
img.lazyloaded {
  /* prepare the future animation */
  transition: opacity 0.5s ease-out 0.2s;
  /* we set the opacity to 1 to do the magic */
  opacity: 1;
}

/* stylelint-enable */
/* ==========================================================================
   #LINKS
   ========================================================================== */
a {
  text-decoration: none;
  border-bottom: 1px solid transparent;
  padding-bottom: 3px;
}

a:hover {
  color: #999;
  border-bottom: 1px solid;
}

.wp-block-columns a {
  border-bottom: 1px solid #888;
}

.wp-block-columns a:hover {
  border-bottom: 1px solid #999;
}

/* ==========================================================================
   #LISTS
   ========================================================================== */
li {
  margin-bottom: 0.5rem;
}

li > ul,
li > ol {
  margin-bottom: 0;
  margin-top: 0.5rem;
}

dt {
  font-weight: bold;
}

dd {
  margin: 0;
}

/* ==========================================================================
   #TABLES
   ========================================================================== */
/**
 * 1. Ensure tables fill up as much space as possible.
 */
table {
  width: 100%;
  /* [1] */
}

/* ==========================================================================
   #GRID
   ========================================================================== */
/* Taken from prototype */
.o-grid, .c-accordian__panel, .c-body, .c-gallery, .c-layout {
  margin: 0 auto;
  transition: all 600ms ease-in-out;
}

@media (min-width: 480px) {
  .o-grid, .c-accordian__panel, .c-body, .c-gallery, .c-layout {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}

@media (min-width: 1024px) {
  .o-grid, .c-accordian__panel, .c-body, .c-gallery, .c-layout {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media (min-width: 1024px) {
  .o-grid--2, .c-gallery {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}

@media (min-width: 1280px) {
  .o-grid--3 {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media (min-width: 1280px) {
  .o-grid--4 {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

.o-grid--mobile {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.o-grid--gutter, .c-body, .c-gallery {
  grid-column-gap: 18px;
  grid-row-gap: 30px;
}

.o-grid--contrast {
  grid-row-gap: 9px;
}

@media (min-width: 480px) {
  .o-grid--contrast {
    grid-template-columns: 1fr;
  }
}

@media (min-width: 1024px) {
  .o-grid--contrast {
    grid-template-columns: 1fr 1fr 1fr;
  }
  .o-grid--contrast:nth-child(even) *:last-child {
    grid-column: span 2;
    grid-row: span 2;
  }
  .o-grid--contrast:nth-child(even) *:nth-child(2) {
    grid-column: 1;
    grid-row: 2;
  }
  .o-grid--contrast:nth-child(odd) *:first-child {
    grid-column: span 2;
    grid-row: span 2;
  }
  .o-grid--contrast:nth-child(odd) *:nth-child(3) {
    grid-column: 3;
    grid-row: 2;
  }
}

/* ==========================================================================
   #LAYOUT
   ========================================================================== */
/* Simple flexbox layout system */
.o-layout {
  display: block;
}

@media (min-width: 768px) {
  .o-layout {
    display: flex;
  }
}

.o-layout__item {
  flex: 1;
}

.o-layout--guttered {
  justify-content: space-between;
}

.o-layout--guttered > .o-layout__item {
  flex: none;
}

@media (min-width: 768px) {
  .o-layout--2 > .o-layout__item {
    width: 49%;
  }
}

@media (min-width: 768px) {
  .o-layout--3 > .o-layout__item {
    width: 32%;
  }
}

@media (min-width: 768px) {
  .o-layout--4 > .o-layout__item {
    width: 24%;
  }
}

/* ==========================================================================
   #LIST-BARE
   ========================================================================== */
/**
 * Strip list-like appearance from lists by removing their bullets, and any
 * indentation.
 */
.o-list-bare {
  list-style: none;
  margin-left: 0;
}

/* ==========================================================================
   #LIST-INLINE
   ========================================================================== */
/**
 * The list-inline object simply displays a list of items in one line.
 */
.o-list-inline, .c-nav__list {
  list-style: none;
  margin-left: 0;
}

.o-list-inline__item, .c-nav__item {
  display: inline-block;
}

/* ==========================================================================
   #MEDIA
   ========================================================================== */
/**
 * Place any image- and text-like content side-by-side, as per:
 * http://www.stubbornella.org/content/2010/06/25/the-media-object-saves-hundreds-of-lines-of-code
 */
.o-media {
  display: block;
}

.o-media:after {
  content: '' !important;
  display: block !important;
  clear: both !important;
}

.o-media__img {
  float: left;
  margin-right: 1rem;
}

.o-media__img > img {
  display: block;
}

.o-media__body {
  display: block;
  overflow: hidden;
}

.o-media__body,
.o-media__body > :last-child {
  margin-bottom: 0;
}

/* Reversed media objects
   ========================================================================== */
.o-media--reverse > .o-media__img {
  float: right;
  margin-left: 1rem;
  margin-right: 0;
}

/* Gutterless media objects
   ========================================================================== */
.o-media--flush > .o-media__img {
  margin-left: 0;
  margin-right: 0;
}

/* ==========================================================================
   #TABLE
   ========================================================================== */
/**
 * A simple object for manipulating the structure of HTML `table`s.
 */
.o-table {
  width: 100%;
}

/* Equal-width table cells.
   ========================================================================== */
/**
 * `table-layout: fixed` forces all cells within a table to occupy the same
 * width as each other. This also has performance benefits: because the browser
 * does not need to (re)calculate cell dimensions based on content it discovers,
 * the table can be rendered very quickly. Further reading:
 * https://developer.mozilla.org/en-US/docs/Web/CSS/table-layout#Values
 */
.o-table--fixed {
  table-layout: fixed;
}

/* ==========================================================================
   #WRAPPER
   ========================================================================== */
/**
 * Page-level constraining and wrapping elements.
 */
.o-wrapper {
  margin-right: auto;
  margin-left: auto;
  max-width: calc(1024px + 2rem);
  padding-left: 1rem;
  padding-right: 1rem;
}

.o-wrapper:after {
  content: '' !important;
  display: block !important;
  clear: both !important;
}

.c-accordians {
  margin-bottom: 60px;
}

.c-accordian__button {
  cursor: pointer;
  width: 100%;
  border-bottom: 1px solid;
  display: flex;
  padding: 18px 0;
  justify-content: space-between;
  align-items: center;
  user-select: none;
}

.c-accordian__panel {
  margin: 30px 0;
  display: none;
  overflow: hidden;
}

.c-accordian__toggle {
  background-image: url("data:image/svg+xml,%3Csvg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 13H30V17H0V13Z' fill='white'/%3E%3Cpath d='M17 1.74845e-07L17 30H13L13 0L17 1.74845e-07Z' fill='white'/%3E%3C/svg%3E") !important;
}

.c-accordian .c-accordian__button.js-active .c-accordian__toggle:after {
  content: none;
}

.c-accordian .c-accordian__button.js-active .c-accordian__toggle {
  background-image: url("data:image/svg+xml,%3Csvg width='30' height='4' viewBox='0 0 30 4' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='30' height='4' fill='white'/%3E%3C/svg%3E") !important;
}

.c-accordian .c-accordian__button.js-active + .c-accordian__panel {
  display: grid;
}

@keyframes intro_logo {
  0% {
    bottom: 0;
    top: auto;
    left: 100vw;
    width: 100vw;
    transform: translateX(0);
  }
  55% {
    opacity: 1;
  }
  85% {
    transform: translateX(calc(-100% - 60px));
    left: 0;
    bottom: 0;
    top: auto;
    width: 100vw;
    opacity: 0;
  }
  86% {
    transform: translateX(0);
    width: 200px;
    bottom: auto;
    top: 0;
  }
  95% {
    opacity: 0;
  }
  100% {
    top: 0;
    opacity: 1;
  }
}

@keyframes intro_clip {
  from {
    left: 100vw;
    opacity: 0;
  }
  to {
    left: 0;
    opacity: 1;
  }
}

@keyframes intro_menu_mobile {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes intro_menu {
  0% {
    right: -100%;
  }
  50% {
    background: #000;
    opacity: 0;
  }
  75% {
    right: 0;
  }
  100% {
    background-color: #fff;
    opacity: 1;
    right: 0;
  }
}

@keyframes closeIcon {
  100% {
    transform: rotate(180deg);
  }
}

.c-body__editor {
  grid-column: 1 / 3;
}

@media (min-width: 768px) {
  .c-body__editor {
    padding-right: 33%;
  }
}

.c-body__editor a {
  text-decoration: underline;
  text-decoration-style: dotted;
}

.c-body__editor a:hover {
  text-decoration-style: solid;
  border: 0;
}

@media (min-width: 1024px) {
  .c-body__social {
    text-align: right;
  }
}

.c-body__meta-column {
  width: 180px;
}

.c-btn {
  padding: 6px 18px;
  display: inline-block;
  color: #fff !important;
  background: #000;
  line-height: 1;
  margin-right: 18px;
  border: 0 !important;
}

.c-btn--trigger, .c-accordian__toggle, .c-media__trigger:after {
  padding: 9px;
  border: 0 !important;
  cursor: pointer;
  height: 48px;
  width: 48px;
  display: block;
  background: #000 no-repeat center;
  background-image: url("data:image/svg+xml,%3Csvg width='28' height='30' viewBox='0 0 28 30' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M21.2319 13.9999L4 25L4 30L27.2319 15L21.2319 13.9999Z' fill='white'/%3E%3Cpath d='M21.2319 15.9918L4 5L4 1.74845e-07L27.2319 15L21.2319 15.9918Z' fill='white'/%3E%3Cpath d='M4 1.74845e-07L4 30H0L1.31134e-06 0L4 1.74845e-07Z' fill='white'/%3E%3C/svg%3E");
}

.c-btn--trigger--close, .c-media__trigger-close:after {
  background-image: url("data:image/svg+xml,%3Csvg width='30' height='4' viewBox='0 0 30 4' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='30' height='4' fill='white'/%3E%3C/svg%3E");
}

.c-theme--film-and-tv .c-btn,
.c-theme--vfx .c-btn {
  filter: invert(1);
}

.c-doorway {
  background-color: #000;
  position: absolute;
  width: 95%;
  height: 100vh;
  left: 2.5%;
  top: 0;
}

.c-doorway__item {
  position: relative;
}

.c-doorway__link {
  animation: fadeDoorIn 2s cubic-bezier(0.23, 1, 0.32, 1) normal forwards;
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1);
  text-align: center;
  color: #fff;
  position: absolute;
  z-index: 10;
  opacity: 0;
  margin: 0;
  width: 100%;
  line-height: 1em;
}

@media (min-width: 768px) {
  .c-doorway__link {
    transform: translateY(-50%);
    top: 50%;
  }
}

.c-doorway__door {
  z-index: 1;
  background-color: #000;
  margin: 0 !important;
  background-size: cover;
  background-position: center;
  border-top: 8vw solid #000;
  border-bottom: 5vw solid #000;
  border-right: 2.5vw solid #000;
  border-left: 2.5vw solid #000;
}

@media (min-width: 768px) {
  .c-doorway__door {
    transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1);
    position: absolute !important;
    height: 100vh !important;
    top: 0;
  }
}

.c-doorway__door .c-media__video {
  animation: fadeDoorIn 3s cubic-bezier(0.23, 1, 0.32, 1) normal forwards;
  height: 100%;
  max-height: none;
}

.c-doorway__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.c-doorway__clip {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.c-doorway__clip-link {
  position: absolute;
  top: 50%;
  left: 0;
  width: 90%;
  margin: 0 5%;
  transform: translateY(calc(-50% + 4em));
  text-align: center;
  z-index: 100000;
  color: #fff !important;
  transition: all 0.15s ease-in-out;
  opacity: 0;
}

.c-doorway__link .c-underline-link {
  max-width: 255px;
  margin: 0 auto;
  text-align: center;
  display: inline-block;
}

.c-doorway__link .c-underline-link::before {
  bottom: -8px;
}

@media (min-width: 768px) {
  .c-doorway__link:hover {
    z-index: 100;
    padding-right: 5rem;
  }
  .c-doorway__link:hover:after {
    content: url('data:image/svg+xml;utf8,<svg width="51" height="16" viewBox="0 0 51 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M50.7071 8.70711C51.0976 8.31658 51.0976 7.68342 50.7071 7.29289L44.3431 0.928932C43.9526 0.538408 43.3195 0.538408 42.9289 0.928932C42.5384 1.31946 42.5384 1.95262 42.9289 2.34315L48.5858 8L42.9289 13.6569C42.5384 14.0474 42.5384 14.6805 42.9289 15.0711C43.3195 15.4616 43.9526 15.4616 44.3431 15.0711L50.7071 8.70711ZM0 9H50V7H0V9Z" fill="white"/></svg>');
    transform: translateX(0.5em);
    color: #fff;
    position: absolute;
    top: -2px;
  }
  .c-doorway__link:hover + .c-doorway__door {
    width: 100%;
    z-index: 99;
    left: 0 !important;
  }
  .c-doorway__link:hover + .c-doorway__door .c-media__video {
    opacity: 0.5;
  }
  .c-doorway__link:hover + .c-doorway__door .c-doorway__clip-link {
    opacity: 0;
    transition-duration: 0.15s;
  }
}

.c-doorway__clip[style*='top: 0%;'] .c-doorway__clip-link {
  opacity: 1;
  transition-duration: 0.5s;
}

/* The animation code */
@keyframes fadeDoorIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 100%;
  }
}

.c-gallery > *:nth-child(3n + 3) {
  grid-column: 1 / 3;
}

.c-gallery .c-tease--clips {
  margin: 0;
}

.c-layout {
  grid-template-columns: 200px 1fr;
  gap: 4em;
}

.c-lightbox {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #000;
}

.c-lightbox__item {
  padding: 1rem;
  max-width: calc(1280px + 2rem);
  position: relative;
  z-index: 999999;
}

.js-active-lightbox .c-wrapper {
  opacity: 1;
  animation: none;
  width: 100%;
  max-width: none;
  overflow: hidden;
  height: calc(100vh - 92px);
}

@media (min-width: 768px) {
  .js-active-lightbox .c-wrapper {
    height: 100vh;
  }
}

.js-active-lightbox .c-nav {
  display: none;
}

.js-active-lightbox .c-lightbox {
  display: flex;
}

.c-logo {
  display: block;
  width: 30px;
  margin: 30px 15px;
  left: 0;
  top: 0;
  border: 0 !important;
}

@media (min-width: 768px) {
  .c-logo {
    margin: 30px;
    position: fixed;
  }
}

.c-logo__img {
  filter: invert(1);
}

.c-media {
  position: relative;
  margin-bottom: 30px;
}

.c-media__video {
  display: block;
  object-fit: cover;
  max-height: 768px;
  width: 100%;
}

@media (max-width: 767.98px) {
  .c-media__video {
    height: 100vw;
  }
}

.c-media__trigger {
  transition: all 0.15s ease-out;
  position: absolute !important;
  cursor: pointer;
  z-index: 100;
  height: 100%;
  width: 100%;
  padding: 0;
  left: 0;
  top: 0;
}

.c-media__trigger:after {
  transition: all 150ms;
  position: absolute;
  content: '';
  bottom: 0;
  right: 0;
}

.c-media__trigger:hover:after {
  height: 80px;
  width: 80px;
}

.c-media__trigger-close:hover:after {
  animation: closeIcon 0.3s forwards ease-in-out;
}

.c-media--project {
  margin-bottom: 30px;
}

.c-media--overlay {
  justify-content: flex-start !important;
  align-items: flex-start !important;
}

.c-media--overlay .wp-block-cover__inner-container {
  margin: 18px 30px;
  line-height: 1.333333;
  font-weight: 300;
  max-width: 500px;
}

.c-media--animate-clip {
  animation: fadeIn 0.33s 0.17s forwards cubic-bezier(0.25, 0.46, 0.45, 0.94);
  opacity: 0;
}

.c-nav {
  margin: 0;
  user-select: none;
}

.c-nav__item {
  margin-bottom: 2em;
}

.c-nav__subitem {
  opacity: 0.5;
}

.c-nav__link {
  cursor: pointer;
}

.c-nav__dropdown {
  list-style-type: none;
}

.c-nav--submenu .c-nav__list {
  position: sticky;
  top: 8em;
}

.c-nav--submenu .c-nav__item {
  display: block;
}

.c-nav--submenu .js-click-toggle {
  cursor: default;
  border: none;
}

.c-nav--submenu .c-nav__dropdown {
  margin-left: 0;
}

.c-nav__link--active,
.c-nav__item.current-menu-item .c-nav__link,
.c-nav__item.current-page-ancestor .c-nav__link {
  border-bottom: 1px solid;
}

.c-nav--main {
  padding-bottom: 90px;
  transition: all 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transform: translateY(0);
  margin: 0 auto;
  width: calc(100% - 30px);
  opacity: 1 !important;
}

@media (min-width: 768px) {
  .c-nav--main {
    width: calc(100% - 150px);
    max-width: 1410px;
    z-index: 1000;
    position: fixed;
    padding: 28px 0 25px;
    justify-content: space-between;
    display: flex;
    top: 0;
    left: 50%;
    transform: translateX(calc(-50% + 15px));
  }
}

.c-nav--main .c-nav__list {
  margin: 0;
}

.c-nav--main .c-nav__item {
  animation: fadeIn 0.33s 0.5s forwards cubic-bezier(0.25, 0.46, 0.45, 0.94);
  opacity: 0;
  margin-right: 10px;
}

@media (min-width: 768px) {
  .c-nav--main .c-nav__item {
    margin-right: 30px;
    margin-top: 5px;
  }
}

.c-nav--main .c-nav__item:hover .c-nav__dropdown {
  left: auto;
  opacity: 1;
  transform: translateY(0);
  transition: all 0.3s ease-in-out;
}

.c-nav--main .c-nav__dropdown {
  list-style-type: none;
  padding: 1em 4em 2em 1em;
  transform: translateY(1em);
  background: #403d4a;
  position: absolute;
  line-height: 2em;
  left: -99999px;
  margin: 0;
  opacity: 0;
}

@keyframes fadeIn {
  from {
    transform: translateY(1em);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.c-scroll-block {
  position: relative;
}

.c-scroll-block__title {
  position: sticky;
  top: 110px;
  z-index: 1;
}

.c-search__input {
  color: inherit;
  padding: 0.5rem;
  border-bottom: 1px solid rgba(127, 127, 127, 0.5);
  max-width: none;
  animation: fadeIn 0.33s 0.5s forwards cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition: all 600ms ease-in-out;
  opacity: 0;
  outline: none;
  background: url("/wp-content/themes/unit-chisel/dist/assets/images/mag-glass.svg") no-repeat right center;
  padding-right: 1.5em;
}

.c-search__input:active, .c-search__input:focus {
  border-bottom: 1px solid #7f7f7f;
}

.c-search ::placeholder {
  color: inherit;
  opacity: 0.5;
  /* Firefox */
}

@media (max-width: 829.98px) {
  .c-search {
    margin: 10px 0 0 -7px;
  }
}

.c-slider {
  width: 100%;
  aspect-ratio: 16 / 9;
}

.c-slider img {
  width: 100%;
}

.c-social-icon:not(:first-of-type) {
  margin-left: 18px;
}

.c-theme--film-and-tv .c-social-icon,
.c-theme--vfx .c-social-icon {
  filter: invert(1);
}

.c-social-icon:hover {
  border: 0;
}

@media (min-width: 1024px) {
  .c-tease--news:first-child {
    grid-row: 1 / 3;
    grid-column: 1 / 3;
  }
  .c-tease--news:first-child a {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    border: 0 !important;
  }
  .c-tease--news:first-child img {
    flex-grow: 1;
    object-fit: cover;
  }
}

.c-tease--journal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}

.c-tease--journal a {
  border: 0 !important;
}

.c-tease--journal img {
  margin: 0 auto;
  display: block;
}

.c-tease--clip span {
  margin: 0.5em 0;
  color: #969696;
  border: 1px solid rgba(150, 150, 150, 0.85);
  font-size: 0.75em;
  padding: 0 3px;
  display: inline-block;
}

.c-tease--clip a {
  border-bottom: none;
}

@media (min-width: 480px) {
  .c-tease--clip a {
    position: relative;
    display: block;
    padding: 0;
    background: black;
  }
  .c-tease--clip img {
    transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    margin: 0 !important;
    aspect-ratio: 16/9;
    object-fit: cover;
  }
  .c-tease--clip div {
    margin: 0 !important;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 1em;
    text-align: bottom;
    flex-direction: column;
    justify-content: flex-end;
    display: flex;
  }
  .c-tease--clip h2, .c-tease--clip .u-h2 {
    color: white;
    transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }
  .c-tease--clip span {
    color: rgba(255, 255, 255, 0.75);
    border: 1px solid rgba(255, 255, 255, 0.75);
  }
}

.c-tease--4\/3 > a {
  aspect-ratio: 4/3;
}

.c-tease--4\/3 img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.c-theme {
  background: #000;
  color: #fff;
}

.c-theme--front-page .c-logo {
  width: 140px;
  color: #fff;
  position: fixed;
  z-index: 9999;
}

.c-theme--front-page .c-body {
  display: none;
}

.c-theme--front-page .c-nav.c-nav--toplevel {
  display: none;
}

.c-theme a {
  color: currentColor;
}

.c-underline-link {
  position: relative;
  border: none;
}

.c-underline-link::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: #fff;
  transform: scale(0);
  transform-origin: center;
  transition: opacity 300ms, transform 300ms;
  z-index: 999;
}

.c-underline-link:hover {
  border: none;
}

.c-underline-link:hover::before, .c-underline-link:focus::before {
  transform: scale(1);
}

.blocks-gallery-grid .blocks-gallery-image,
.blocks-gallery-grid .blocks-gallery-item,
.wp-block-gallery .blocks-gallery-image,
.wp-block-gallery .blocks-gallery-item {
  width: calc((100% - 18px) / 2);
  margin: 0 18px 18px 0;
}

.custom-embed {
  display: block;
  height: calc((100vw - 2rem) * 0.6666666667) !important;
  max-height: 600px;
}

.c-wrapper {
  margin: 0 auto 30px;
  width: calc(100% - 30px);
  max-width: 1440px;
  animation: fadeIn 0.33s 0.5s forwards cubic-bezier(0.25, 0.46, 0.45, 0.94);
  opacity: 0;
}

@media (min-width: 768px) {
  .c-wrapper {
    width: calc(100% - 120px);
    padding-top: 110px;
    padding-left: 30px;
  }
}

@media only screen and (min-width: 768px) and (min-height: 800px) {
  .c-wrapper {
    padding-top: 140px;
  }
}

/* ==========================================================================
   #ALIGN
   ========================================================================== */
.u-float-left {
  float: left;
}

.u-float-right {
  float: right;
}

.u-clear {
  clear: both;
}

.u-text-center {
  text-align: center;
}

.u-text-left {
  text-align: left;
}

.u-text-right {
  text-align: right;
}

.u-flex {
  display: flex;
}

.u-flex--vcenter {
  flex-direction: column;
  justify-content: center;
}

.u-flex--vbottom {
  flex-direction: column;
  justify-content: flex-end;
}

/* ==========================================================================
   #CLEARFIX
   ========================================================================== */
/**
 * Attach our clearfix mixin to a utility class.
 */
.u-clearfix:after {
  content: '' !important;
  display: block !important;
  clear: both !important;
}

/* ==========================================================================
   #HIDE
   ========================================================================== */
/**
 * Hide only visually, but have it available for screen readers:
 * http://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 */
.u-hidden-visually {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  white-space: nowrap !important;
  width: 1px !important;
}

/**
 * Hide visually and from screen readers.
 */
.u-hidden {
  display: none !important;
}

.u-hidden\@small {
  display: none;
}

@media (min-width: 768px) {
  .u-hidden\@small {
    display: block;
  }
}

/* ==========================================================================
   #MARGINS & PADDING
   ========================================================================== */
.u-margin {
  margin: 30px !important;
}

.u-margin-top, .custom-embed {
  margin-top: 30px !important;
}

.u-margin-top--medium {
  margin-top: 18px !important;
}

.u-margin-top--small {
  margin-top: 6px !important;
}

.u-margin-right {
  margin-right: 30px !important;
}

.u-margin-right--medium {
  margin-right: 18px !important;
}

.u-margin-right--small {
  margin-right: 6px !important;
}

.u-margin-bottom, .c-body__editor, .c-body__social, .custom-embed {
  margin-bottom: 30px !important;
}

.u-margin-bottom--medium {
  margin-bottom: 18px !important;
}

.u-margin-bottom--small {
  margin-bottom: 6px !important;
}

.u-margin-left {
  margin-right: 30px !important;
}

.u-margin-left--medium {
  margin-right: 18px !important;
}

.u-margin-left--small {
  margin-right: 6px !important;
}

.u-margin-none, .c-body__editor *:last-child {
  margin: 0 !important;
}

.u-padding {
  padding: 30px !important;
}

.u-padding-top {
  padding-top: 30px !important;
}

.u-padding-right {
  padding-right: 30px !important;
}

.u-padding-bottom {
  padding-bottom: 30px !important;
}

.u-padding-left {
  padding-right: 30px !important;
}

.u-padding-none {
  padding: 0 !important;
}

.u-text--upper, input[type='submit'] {
  text-transform: uppercase;
}

.u-text--weight-normal {
  font-weight: normal;
}

.u-text--superheader {
  font-size: 4.3636363637rem;
  line-height: 3.5656565657rem;
}

@media (min-width: 768px) {
  .u-text--superheader {
    font-size: 8.1818181818rem;
    line-height: 6.3636363636rem;
  }
}

.u-text--serif {
  font-family: "ivypresto-display", serif;
}

.u-link--naked {
  text-decoration: none;
}

.u-link--dud {
  cursor: pointer;
}

.u-p {
  font-size: 1rem !important;
  line-height: 1.5 !important;
}

.u-title {
  font-size: 2rem !important;
  line-height: 1.5 !important;
}

.u-columns .wp-block-group__inner-container {
  columns: 2 200px;
  column-gap: 18px;
}
