.c-nav {
  margin: 0;
  user-select: none;

  &__list {
    @extend .o-list-inline;
  }

  &__item {
    @extend .o-list-inline__item;
    margin-bottom: 2em;
  }

  &__subitem {
    opacity: 0.5;
  }

  &__link {
    cursor: pointer;
  }

  &__dropdown {
    list-style-type: none;
  }
}

.c-nav--submenu {
  .c-nav__list {
    position: sticky;
    top: 8em;
  }

  .c-nav__item {
    display: block;
  }

  .js-click-toggle {
    cursor: default;
    border: none;
  }

  .c-nav__dropdown {
    margin-left: 0;
  }
}

.c-nav__link--active,
.c-nav__item.current-menu-item .c-nav__link,
.c-nav__item.current-page-ancestor .c-nav__link {
  border-bottom: 1px solid;
}

.c-nav--main {
  padding-bottom: 90px;
  transition: all 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transform: translateY(0);
  margin: 0 auto;
  width: calc(100% - 30px);
  opacity: 1 !important;

  @include bp(medium) {
    width: calc(100% - 150px);
    max-width: 1410px;
    z-index: 1000;
    position: fixed;
    padding: 28px 0 25px;
    justify-content: space-between;
    display: flex;
    top: 0;
    left: 50%;
    transform: translateX(calc(-50% + 15px));
  }

  .c-nav__list {
    margin: 0;
  }

  .c-nav__item {
    animation: fadeIn 0.33s 0.5s forwards cubic-bezier(0.25, 0.46, 0.45, 0.94);
    opacity: 0;
    margin-right: 10px;

    @include bp(medium) {
      margin-right: 30px;
      margin-top: 5px;
    }
  }

  .c-nav__item:hover .c-nav__dropdown {
    left: auto;
    opacity: 1;
    transform: translateY(0);
    transition: all 0.3s ease-in-out;
  }

  .c-nav__dropdown {
    list-style-type: none;
    padding: 1em 4em 2em 1em;
    transform: translateY(1em);
    background: #403d4a;
    position: absolute;
    line-height: 2em;
    left: -99999px;
    margin: 0;
    opacity: 0;
  }
}

@keyframes fadeIn {
  from {
    transform: translateY(1em);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}
