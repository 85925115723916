button,
input,
optgroup,
select,
textarea {
  font-family: $font-sans-serif !important;
  background: transparent;
  color: white;
  max-width: 350px;
  display: block;
  padding: 1rem 0;
  width: 100%;
  border: 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

input[type='submit'] {
  @extend .u-text--upper;
  background: url('/wp-content/themes/unit-chisel/dist/assets/images/cta.svg');
  background-repeat: no-repeat;
  background-position: right;
  background-size: 1.25rem;
  padding-right: 1.5rem;
  cursor: pointer;
  width: auto;
  border: 0;
}
