/* ==========================================================================
   #IMAGES
   ========================================================================== */

/**
 * 1. Fluid images for responsive purposes.
 * 2. Offset `alt` text from surrounding copy.
 * 3. Setting `vertical-align` removes the whitespace that appears under `img`
 *    elements when they are dropped into a page as-is. Safer alternative to
 *    using `display: block;`.
 */

img {
  font-style: italic; /* [2] */
  max-width: 100%; /* [1] */
  vertical-align: middle; /* [3] */
  height: auto;
}

/**
 * 1. If a `width` and/or `height` attribute have been explicitly defined, let’s
 *    not make the image fluid.
 */

img[width],
img[height] {
  max-width: none;
}

/* Image with 'data' attribute is hidden */
img[data-lazy-src] {
  /* we set the opacity to 0 */
  opacity: 0;
}

/* stylelint-disable */
/* Image without 'data' attribute is (or becomes) visible */
img.lazyloaded {
  /* prepare the future animation */
  transition: opacity 0.5s ease-out 0.2s;

  /* we set the opacity to 1 to do the magic */
  opacity: 1;
}
/* stylelint-enable */
