.c-gallery {
  @extend .o-grid;
  @extend .o-grid--2;
  @extend .o-grid--gutter;

  > * {
    &:nth-child(3n + 3) {
      grid-column: 1 / 3;
    }
  }

  .c-tease--clips {
    margin: 0;
  }
}
