.c-social-icon {
  &:not(:first-of-type) {
    margin-left: 18px;
  }

  .c-theme--film-and-tv &,
  .c-theme--vfx & {
    filter: invert(1);
  }

  &:hover {
    border: 0;
  }
}
