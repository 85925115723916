.c-media {
  position: relative;
  margin-bottom: 30px;

  &__video {
    display: block;
    object-fit: cover;
    max-height: 768px;
    width: 100%;

    @include bp-down(medium) {
      height: 100vw;
    }
  }

  &__trigger {
    transition: all 0.15s ease-out;
    position: absolute !important;
    cursor: pointer;
    z-index: 100;
    height: 100%;
    width: 100%;
    padding: 0;
    left: 0;
    top: 0;

    &:after {
      @extend .c-btn--trigger;
      transition: all 150ms;
      position: absolute;
      content: '';
      bottom: 0;
      right: 0;
    }

    &:hover {
      &:after {
        height: 80px;
        width: 80px;
      }
    }
  }

  &__trigger-close {
    &:after {
      @extend .c-btn--trigger--close;
    }

    &:hover {
      &:after {
        animation: closeIcon 0.3s forwards ease-in-out;
      }
    }
  }
}

.c-media--project {
  margin-bottom: 30px;
}

.c-media--overlay {
  justify-content: flex-start !important;
  align-items: flex-start !important;

  .wp-block-cover__inner-container {
    margin: 18px 30px;
    line-height: 1.333333;
    font-weight: 300;
    max-width: 500px;
  }
}

.c-media--animate-clip {
  animation: fadeIn 0.33s 0.17s forwards cubic-bezier(0.25, 0.46, 0.45, 0.94);
  opacity: 0;
}
