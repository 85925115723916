.c-body {
  @extend .o-grid;
  @extend .o-grid--gutter;

  &__editor {
    @extend .u-margin-bottom;
    grid-column: 1 / 3;

    @include bp(medium) {
      padding-right: 33%;
    }

    *:last-child {
      @extend .u-margin-none;
    }

    a {
      text-decoration: underline;
      text-decoration-style: dotted;

      &:hover {
        text-decoration-style: solid;
        border: 0;
      }
    }
  }

  &__social {
    @extend .u-margin-bottom;

    @include bp(large) {
      text-align: right;
    }
  }

  &__meta-column {
    width: 180px;
  }
}
