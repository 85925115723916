/* ==========================================================================
   #GRID
   ========================================================================== */

/* Taken from prototype */

.o-grid {
  margin: 0 auto;
  transition: $global-transition;

  @include bp(small) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  @include bp(large) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.o-grid--2 {
  @include bp(large) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}

.o-grid--3 {
  @include bp(xlarge) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.o-grid--4 {
  @include bp(xlarge) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

.o-grid--mobile {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.o-grid--gutter {
  grid-column-gap: 18px;
  grid-row-gap: 30px;
}

.o-grid--contrast {
  grid-row-gap: 9px;

  @include bp(small) {
    grid-template-columns: 1fr;
  }

  @include bp(large) {
    grid-template-columns: 1fr 1fr 1fr;

    &:nth-child(even) {
      *:last-child {
        grid-column: span 2;
        grid-row: span 2;
      }

      *:nth-child(2) {
        grid-column: 1;
        grid-row: 2;
      }
    }

    &:nth-child(odd) {
      *:first-child {
        grid-column: span 2;
        grid-row: span 2;
      }

      *:nth-child(3) {
        grid-column: 3;
        grid-row: 2;
      }
    }
  }
}
