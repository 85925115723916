.c-logo {
  display: block;
  width: 30px;
  margin: 30px 15px;
  left: 0;
  top: 0;
  border: 0 !important;

  @include bp(medium) {
    margin: 30px;
    position: fixed;
  }
}

.c-logo__img {
  filter: invert(1);
}
