.c-wrapper {
  margin: 0 auto 30px;
  width: calc(100% - 30px);
  max-width: 1440px;
  animation: fadeIn 0.33s 0.5s forwards cubic-bezier(0.25, 0.46, 0.45, 0.94);
  opacity: 0;

  @include bp(medium) {
    width: calc(100% - 120px);
    padding-top: 110px;
    padding-left: 30px;
  }
}

@media only screen and (min-width: 768px) and (min-height: 800px) {
  .c-wrapper {
    padding-top: 140px;
  }
}
