/* ==========================================================================
   #LINKS
   ========================================================================== */

a {
  text-decoration: none;
  border-bottom: 1px solid transparent;
  padding-bottom: 3px;

  &:hover {
    color: $color-hover;
    border-bottom: 1px solid;
  }
}

.wp-block-columns a {
  border-bottom: 1px solid #888;

  &:hover {
    border-bottom: 1px solid $color-hover;
  }
}
