.c-theme {
  background: $color-black;
  color: $color-white;

  &--front-page {
    .c-logo {
      width: 140px;
      color: $color-white;
      position: fixed;
      z-index: 9999;
    }

    .c-body {
      display: none;
    }

    .c-nav.c-nav--toplevel {
      display: none;
    }
  }

  a {
    color: currentColor;
  }
}
