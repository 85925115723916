.c-search {
  &__input {
    color: inherit;
    padding: 0.5rem;
    border-bottom: 1px solid rgba(127, 127, 127, 0.5);
    max-width: none;
    animation: fadeIn 0.33s 0.5s forwards cubic-bezier(0.25, 0.46, 0.45, 0.94);
    transition: $global-transition;
    opacity: 0;
    outline: none;
    background: url('/wp-content/themes/unit-chisel/dist/assets/images/mag-glass.svg') no-repeat right center;
    padding-right: 1.5em;

    &:active,
    &:focus {
      border-bottom: 1px solid rgba(127, 127, 127, 1);
    }
  }

  ::placeholder {
    color: inherit;
    opacity: 0.5; /* Firefox */
  }

  @include bp-down(830px) {
    margin: 10px 0 0 -7px;
  }
}
