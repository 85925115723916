.blocks-gallery-grid .blocks-gallery-image,
.blocks-gallery-grid .blocks-gallery-item,
.wp-block-gallery .blocks-gallery-image,
.wp-block-gallery .blocks-gallery-item {
  width: calc((100% - 18px) / 2);
  margin: 0 18px 18px 0;
}

.custom-embed {
  @extend .u-margin-top;
  @extend .u-margin-bottom;
  display: block;
  height: calc((100vw - 2rem) * 0.6666666667) !important;
  max-height: 600px;
}
