@keyframes intro_logo {
  0% {
    bottom: 0;
    top: auto;
    left: 100vw;
    width: 100vw;
    transform: translateX(0);
  }

  55% {
    opacity: 1;
  }

  85% {
    transform: translateX(calc(-100% - 60px));
    left: 0;
    bottom: 0;
    top: auto;
    width: 100vw;
    opacity: 0;
  }

  86% {
    transform: translateX(0);
    width: 200px;
    bottom: auto;
    top: 0;
  }

  95% {
    opacity: 0;
  }

  100% {
    top: 0;
    opacity: 1;
  }
}

@keyframes intro_clip {
  from {
    left: 100vw;
    opacity: 0;
  }

  to {
    left: 0;
    opacity: 1;
  }
}

@keyframes intro_menu_mobile {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes intro_menu {
  0% {
    right: -100%;
  }

  50% {
    background: $color-black;
    opacity: 0;
  }

  75% {
    right: 0;
  }

  100% {
    background-color: $color-white;
    opacity: 1;
    right: 0;
  }
}

@keyframes closeIcon {
  100% {
    transform: rotate(180deg);
  }
}
